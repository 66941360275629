import { Navigate } from '@tanstack/react-router'
import { memo, useEffect } from 'react'
import { trpcReact } from '../../../client'
import { useStartSelfOnboarding } from '../../../lib/hooks/use-start-self-onboarding'
import { GlobalLoadingState } from '../../molecules/global-loading-state'

const NavigateToNewFunnel = memo(function NavigateToNewFunnel() {
  const pendingSubscriptionId = trpcReact.subscriptions.getPendingSubscriptionId.useQuery()

  return pendingSubscriptionId.isLoading ? (
    <GlobalLoadingState />
  ) : pendingSubscriptionId.data ? (
    <Navigate
      to={'/subscribe/$subscriptionId/$stepId'}
      params={{ subscriptionId: pendingSubscriptionId.data, stepId: 'quote' }}
    />
  ) : (
    <StartSelfOnboarding />
  )
})

export default NavigateToNewFunnel

const StartSelfOnboarding = memo(function StartSelfOnboarding() {
  const startSelfOnboarding = useStartSelfOnboarding()
  useEffect(() => {
    startSelfOnboarding()
  }, [startSelfOnboarding])
  return <GlobalLoadingState />
})
